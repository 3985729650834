const defaultFlags = {
  'acs.access.enable': false,
  'advanced_planning_platform.enable': true,
  'argon_2_encryption.enable': false,
  'authentication.saml.multiple_configs.enabled': false,
  'badge-csv-connector-ui-improvements': false,
  'client_feedback_link.enable': false,
  'connectors.acs.brivo.events.enable': false,
  'connectors.acs.extended.ui.enable': false,
  'connectors.acs.kisi.enable': false,
  'connectors.acs.openpath.enable': false,
  'connectors.acs.openpath.events.enable': false,
  'connectors.network.cisco.pxgrid.enable': false,
  'connectors.network.cisco.meraki.enable': false,
  'connectors.new.ui': false,
  'desk_lending.enable': false,
  'developer_api.enable': true,
  'employee_field.configuration_page.enable': false,
  'employee_field.component_configuration_exposure.enable': false,
  'employee_field.manager_employee_filter_fields.new_source.enable': false,
  'facility.leases.data_access.enable': true,
  'favorite_employees.enable': false,
  'free_addressing_sensors.enable': false,
  'full_function_neighborhoods.enable': false,
  'gaia.insights_hub_landing_page.enable': false,
  'google.dialogflow.messenger.config': {
    agentId: 'd1aff07e-881f-4413-b59f-75187b6b494c',
    chatBubble: {
      anchor: 'bottom-left',
      chatTitle: 'Ossie, your AI Assistant',
    },
    intent: '',
    languageCode: 'en',
    maxQueryLength: -1,
    projectId: 'oss-development-323115',
  },
  'huddle.kafka.publish.enable': false,
  'insights_hub.advanced_analytics.enable': false,
  'insights_hub.advanced_analytics.oss_override.enable': false,
  'insights_hub.booking_usage_by_occupant_floor_permissions.enable': false,
  'insights_hub.core_product.data_access.value': '',
  'insights_hub.daily_snapshot_data_export.v1.enable': false,
  'insights-hub-dashboard-studio': false,
  'insights_hub.desk_reservation_trends_cross_site_summary.enable': false,
  'insights_hub.desk_reservation_trends_site_summary.enable': false,
  'insights_hub.employee_attendance.enable': false,
  'insights_hub.employee_attendance_cross_site_summary.enable': false,
  'insights_hub.employee_attendance_export.enable': false,
  'insights_hub.employee_attendance_site_summary.enable': false,
  'insights-hub-employee-presence': false,
  'insights-hub-employee-presence-oss-override': false,
  'insights-hub-employee-presence-custom-filters': false,
  'insights-hub-graph-ql-permissions-and-filtering': false,
  'insights-hub-hide-shared-desks-from-reports': false,
  'insights_hub.inactive_people_desk_assignments.enable': false,
  'insights_hub.people_report_field_config.enable': false,
  'insights_hub.perc_dept_changes.enable': false,
  'insights_hub.rbd_key_metrics_ref.enable': false,
  'insights_hub.rbt_dashboard.hide_room_fit.enable': false,
  'insights_hub.real_estate.data_access.enable': true,
  'insights_hub.responsive_landing_page.enable': false,
  'insights_hub.restructured_real_estate_reports.enable': false,
  'insights_hub.room_booking_dashboard.enable': false,
  'insights-hub-room-booking-dashboard-usage-data': false,
  'insights_hub.seat_report_shifts.enable': false,
  'insights_hub.space_booking.data_access.value': '',
  'insights_hub.working_trends_dashboard.data_export.enable': false,
  'insights_hub.working_trends_dashboard_v2.enable': false,
  'insights-hub-workplace-trends-badge-heatmap': false,
  'insights-hub-workplace-trends-badge-heatmap-v2': false,
  'insights-hub-workplace-trends-badge-heatmap-v3': false,
  'insights-hub-workplace-trends-performance': false,
  'kiosk.enable': true,
  language: 'en',
  'manager.move_icons.enable': true,
  'multi_select.enable': true,
  'multi_select.shifts_enhancement.enable': false,
  'planning_platform.growth_forecast.data_access.enable': true,
  'planning_platform.growth_forecast.new_landing_page.enable': false,
  'planning_platform.stacking.data_access.enable': false,
  'presence_check_in.enable': false,
  'request_manager.enable': true,
  'request_manager.business_hours.enable': false,
  'request_manager.per_site_enablement.enable': false,
  'room_booking.enable': true,
  'room_booking.domain-wide_delegation.enable': false,
  'room_booking.office365_restrictions.enable': false,
  'room_booking_overview.enable': false,
  'room_displays.enable': true,
  'room_displays.redesign.enable': false,
  'seat_open_booking.bcg_fix.enable': false,
  'shared_seats.enable': false,
  'shifts.enable': false,
  'stack_plans.stacking.sandbox.enable': false,
  'users.local_auth_settings.enabled': false,
  'users.roles.customizable_settings.data_access.enable': false,
  'users.roles.delegate.creation.enable': false,
  'users.roles.delegate.requests.enable': false,
  'users.roles.new_settings_page.enable': false,
  'visits_by_site_report.enable': false,
  'visual_directory.desk_status_refresh.enable': false,
  'visual_directory.enhanced_request_manager.enable': false,
  'visual_directory.enhanced_request_manager.utc_timezone.enable': false,
  'visual_directory.presence_ui.enable': false,
  'visual_directory.whos_in.favorite_employees_modal.enable': false,
  'visitor_management_integration.enable': false,
  'vms_menu_item.enable': false,
  'webhooks.enable': true,
  'wtd-associated-site-refinements': false,
}

export default defaultFlags
