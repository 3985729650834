import SvgIcon from '@material-ui/core/SvgIcon'

type PropsT = {
  className?: string
}
const InOffice = ({ className }: PropsT): JSX.Element => (
  <SvgIcon className={className} viewBox="0 0 10 10">
    <svg
      fill="none"
      height="10"
      viewBox="0 0 10 10"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M5.00019 2.61961V0.714844H0.238281V9.28627H9.76209V2.61961H5.00019ZM4.04781 8.33389H1.19066V7.38151H4.04781V8.33389ZM4.04781 6.42913H1.19066V5.47675H4.04781V6.42913ZM4.04781 4.52437H1.19066V3.57199H4.04781V4.52437ZM4.04781 2.61961H1.19066V1.66722H4.04781V2.61961ZM8.80971 8.33389H5.00019V3.57199H8.80971V8.33389ZM7.85733 4.52437H5.95257V5.47675H7.85733V4.52437ZM7.85733 6.42913H5.95257V7.38151H7.85733V6.42913Z"
        fill="currentcolor"
      />
    </svg>
  </SvgIcon>
)

export default InOffice
